<template>
  <div class="container-fluid">
    <CRow class="p-3">
      
        <h4 class="text-uppercase">Billing Report</h4>
      
    </CRow>

     <CCard> 
      <CRow  class="p-3 m-4 d-flex justify-content-center">
      <CCol cols="2" class="custom-margin">
        <div class="mr-4 box text-center" @click="goToLinks('Monthly')">
          <h4>Monthly Billing</h4>
        </div>
      </CCol>
      <CCol cols="2" class="custom-margin">
        <div
          class="box text-center"
          @click="goToLinks('Quarterly')"
        >
          <h4>Quarterly Billing</h4>
        </div>
      </CCol>
      <CCol cols="2" class="custom-margin">
        <div
          class="box text-center"
          @click="goToLinks('HalfYearly')"
        >
          <h4>Half Yearly Billing</h4>
        </div>
      </CCol>
      <CCol cols="2" class="custom-margin">
        <div class="box text-center" @click="goToLinks('Yearly')">
          <h4>Yearly Billing</h4>
        </div>
      </CCol>

     
      <!-- <CCol cols="2">
        <div class="box text-center">
          <h4>Custom Date Billing</h4>
        </div>
      </CCol> -->
    </CRow>
    </CCard>
    

    <!-- <CRow  class="p-3 m-4 d-flex justify-content-center">
      <CCol cols="12">
        <div
          class="mr-4 mb-3 box text-center"
          @click="goToLinks('MonthlyReports', 'last_month')"
        >
          <h4>Last Month Billing</h4>
        </div>
      </CCol>
      <CCol cols="12">
        <div
          class="mr-4 mb-3 box text-center"
          @click="goToLinks('QuarterlyBillingReport', 'quarterly')"
        >
          <h4>Quarterly Billing</h4>
        </div>
      </CCol>
      <CCol cols="12">
        <div
          class="mr-4 mb-3 box text-center"
          @click="goToLinks('HalfYearlyBillingReport', 'half_yearly')"
        >
          <h4>Half Yearly Billing</h5>
        </div>
      </CCol>
      <CCol cols="12">
        <div
          class="mr-4 mb-3 box text-center"
          @click="goToLinks('YearlyBillingReport', 'yearly')"
        >
          <h5>Yearly Billing</h5>
        </div>
      </CCol>
      <CCol cols="2">
        <div class="box text-center">
          <h5>Custom Date Billing</h5>
        </div>
      </CCol>
    </CRow> -->
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Reports", path:"/reports", isActive: false}, {text:"Billing", path:"", isActive: true}]
    };
  },
  methods: {
    goToLinks(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style  scoped>
.card {
  border: none;
  padding: 80px 70px;
}
.box {
  -webkit-box-shadow: 5px 5px 15px -2px #0000008c;
  box-shadow: 5px 5px 15px -2px #0000008c;
  padding: 30px;
  cursor: pointer;
  position: relative;
  width: auto;
  height: 180px;
  margin-right: 5px;
  margin-left: 5px;
}

/* .custom-margin{
  margin-right:70px;
  margin-bottom:50px;
} */

.box h4 {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
}
</style>


<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
}
</style>